import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import EnterFullscreenIcon from '@mui/icons-material/Fullscreen';
import ExitFullscreenIcon from '@mui/icons-material/FullscreenExit';
import PushPinIcon from '@mui/icons-material/PushPin';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import styled from 'styled-components';
import { useToggle } from 'usehooks-ts';
import trainerIcon from 'Assets/images/trainerIcon.svg';
import { Colors } from 'Themes/colors';
import { DeleteDesktopRequest, DeleteFreshStartDesktopRequest, DesktopModel } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { selectLoginState, LoginState } from 'Auth/authSlice';
import { UserDisplayModel } from 'User/userDisplayModel';
import { openExternalUrl } from 'Utils/redirects';
import { useBoolean, useElementInFullscreen } from 'Utils/customHooks';
import ConfirmationDialog from 'Common/ConfirmationDialog/ConfirmationDialog';
import {
  ConnectToFreshStartDesktopButton,
  ConnectToMyDesktopButton,
  ConnectToParticipantDesktopButton
} from 'Common/ConnectToDesktopButtons';
import {
  CourseModel,
  deleteFreshStartDesktop,
  deleteMyDesktop,
  deleteParticipantDesktop,
  stopFreshStartDesktop,
  stopMyDesktop,
  stopParticipantDesktop
} from 'Courses/coursesSlice';
import { saveIsMachineBarPinned, selectIsMachineBarPinned } from 'User/userSlice';
import LivePreview from './LivePreview';
import UserInfo from './UserInfo';
import { ActionWrapper, PushPinButton, StyledCard } from './LivePreviewCard.styles';
import ActionButtons from './ActionButtons';

const StyledEnterFullscreenIcon = styled(EnterFullscreenIcon)`
  color: ${Colors.red} !important;
`;

const trainerAvatar = <img src={trainerIcon} alt="trainer icon" />;

type Props = {
  course: CourseModel;
  user: UserDisplayModel;
  desktop: DesktopModel;
  isFreshStartDesktop?: boolean;
  isParticipantDesktop?: boolean;
  isTrainerDesktop?: boolean;
  isTrainerDesktopPreview?: boolean;
  staticSize?: boolean;
  isHighlighted?: boolean;
  isMinimized?: boolean;
};

const LivePreviewCard = ({
  course,
  user,
  desktop,
  isFreshStartDesktop = false,
  isParticipantDesktop = false,
  isTrainerDesktop = false,
  isTrainerDesktopPreview = false,
  staticSize,
  isHighlighted = false,
  isMinimized = false
}: Readonly<Props>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dialogIsDisplayed, displayDialog, hideDialog] = useBoolean();

  const cardRef = useRef<HTMLDivElement>(null);
  const { isFullscreenEnabled, toggleFullscreenMode } = useElementInFullscreen(
    cardRef as React.RefObject<HTMLDivElement>
  );

  const isMachineBarPinned = useAppSelector(selectIsMachineBarPinned);
  const loginState = useAppSelector(selectLoginState);
  const isLogged = loginState === LoginState.LOGGED;

  const handleOpenPreviewOnNewTab = () => {
    const link = isTrainerDesktopPreview ? (desktop.viewLink as string) : (desktop.link as string);

    if (link) {
      openExternalUrl(link);
    }
  };

  const handleStopMachine = () => {
    if (isFreshStartDesktop) {
      dispatch(
        stopFreshStartDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number
        })
      );
    } else if (isParticipantDesktop) {
      dispatch(
        stopParticipantDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number,
          participantId: user.id as string
        })
      );
    } else {
      dispatch(
        stopMyDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number
        })
      );
    }

    toggleSettings();
  };

  const handleRemoveMachine = () => {
    if (isFreshStartDesktop) {
      dispatch(
        deleteFreshStartDesktop({
          courseId: course.id
        } as DeleteFreshStartDesktopRequest)
      );
    } else if (isParticipantDesktop) {
      dispatch(
        deleteParticipantDesktop({
          courseId: course.id as string,
          desktopInstanceId: desktop.instanceId as number,
          participantId: user.id as string
        })
      );
    } else {
      dispatch(
        deleteMyDesktop({
          courseId: course.id,
          desktopInstanceId: desktop.instanceId
        } as DeleteDesktopRequest)
      );
    }

    hideDialog();
    toggleSettings();
  };

  const handleSaveIsMachineBarPinned = () => {
    dispatch(saveIsMachineBarPinned({ isMachineBarPinned: !isMachineBarPinned, isLogged }));
  };

  const FullscreenIcon = isFullscreenEnabled ? ExitFullscreenIcon : StyledEnterFullscreenIcon;

  const [settingsOpened, toggleSettings] = useToggle();

  let connectToDesktopButton: React.ReactElement | null = null;

  if (isFreshStartDesktop) {
    connectToDesktopButton = (
      <ConnectToFreshStartDesktopButton
        courseId={course.id as string}
        isFreshStartMachineReadyToClone={course.isFreshStartMachineReadyToClone}
      />
    );
  } else if (isParticipantDesktop) {
    connectToDesktopButton = (
      <ConnectToParticipantDesktopButton
        courseId={course.id as string}
        participantId={user.id as string}
        isHighlighted={isHighlighted}
        isMinimized={isMinimized}
      />
    );
  } else if (!isTrainerDesktopPreview) {
    connectToDesktopButton = (
      <ConnectToMyDesktopButton
        courseId={course.id as string}
        isHighlighted={isHighlighted}
        isMinimized={isMinimized}
      />
    );
  }

  let componentToRender: React.ReactElement | null = null;

  // TODO: try simplify this condition
  const shouldShowConnectToDesktopButton =
    (desktop?.isRunning && desktop?.instanceId) ||
    (!desktop?.isRunning &&
      ((course.hasFreshStart && course.isFreshStartMachineReadyToClone) || desktop?.instanceId));

  if (shouldShowConnectToDesktopButton) {
    componentToRender = connectToDesktopButton;
  } else {
    componentToRender = <WebAssetOffIcon fontSize="large" />;
  }

  return (
    <>
      <StyledCard
        ref={cardRef}
        fullscreenModeIsEnabled={isFullscreenEnabled}
        staticSize={staticSize}
        settingsOpened={settingsOpened}
        isMinimized={isMinimized}
      >
        {!isMinimized ? (
          <LivePreview
            fullscreenModeIsEnabled={isFullscreenEnabled}
            settingsOpened={settingsOpened}
            course={course}
            desktop={desktop}
            isParticipantDesktop={isParticipantDesktop}
            isTrainerDesktopPreview={isTrainerDesktopPreview}
            connectToDesktopButton={connectToDesktopButton}
          />
        ) : null}

        <ActionWrapper
          fullScreen={isFullscreenEnabled}
          pinEnabled={isMachineBarPinned}
          isMinimized={isMinimized}
        >
          {isFullscreenEnabled ? (
            <PushPinButton pinEnabled={isMachineBarPinned} onClick={handleSaveIsMachineBarPinned}>
              <PushPinIcon />
            </PushPinButton>
          ) : null}

          <UserInfo
            name={user.displayName}
            avatarColor={user.avatarColor}
            avatar={user.avatar}
            customAvatar={
              isTrainerDesktopPreview || isTrainerDesktop ? trainerAvatar : user.customAvatar
            }
          />

          {isMinimized ? componentToRender : null}

          {!isMinimized || shouldShowConnectToDesktopButton ? (
            <ActionButtons
              desktop={desktop}
              isFullscreenEnabled={isFullscreenEnabled}
              isTrainerDesktopPreview={isTrainerDesktopPreview}
              settingsOpened={settingsOpened}
              toggleSettings={toggleSettings}
              handleOpenPreviewOnNewTab={handleOpenPreviewOnNewTab}
              handleStopMachine={handleStopMachine}
              displayDialog={displayDialog}
              FullscreenIcon={FullscreenIcon}
              toggleFullscreenMode={toggleFullscreenMode}
            />
          ) : null}
        </ActionWrapper>
      </StyledCard>

      <ConfirmationDialog
        open={dialogIsDisplayed}
        content={t('RemoveMachineQuestion', { instanceName: desktop?.instanceName })}
        onNo={hideDialog}
        onYes={handleRemoveMachine}
      />
    </>
  );
};

export default LivePreviewCard;
