import { pipe, boolean, email, minLength, nonOptional, string, nonEmpty } from 'valibot'; // 0.63 kB

export const fieldIsRequiredTranslationKey = 'FieldIsRequired';
export const notValidEmailTranslationKey = 'PleaseEnterValidEmailAddress';
export const passwordMustContainAtLeastOneUppercaseLetterTranslationKey =
  'PasswordMustContainAtLeastOneUppercaseLetter';
export const passwordMustContainAtLeastOneNumberTranslationKey =
  'PasswordMustContainAtLeastOneNumber';
export const passwordMustContainAtLeastOneSpecialCharacterTranslationKey =
  'PasswordMustContainAtLeastOneSpecialCharacter';
export const passwordMustHave8CharactersOrMoreTranslationKey = 'PasswordMustHave8CharactersOrMore';

export const stringRequired = () => pipe(string(), minLength(1, fieldIsRequiredTranslationKey));
export const emailWrapper = email(notValidEmailTranslationKey);
export const booleanRequired = nonOptional(boolean(), fieldIsRequiredTranslationKey);
