import React, { memo, ReactNode } from 'react';
import { Navbar } from './Navbar';
import { ApplicationWrapper, ContentWrapper } from './AppLayout.styles';
import Footer from './Footer';

type Props = {
  isForLogged: boolean;
  children: ReactNode;
};

const AppLayout = ({ isForLogged, children }: Readonly<Props>) => {
  return (
    <ApplicationWrapper>
      <Navbar isForLogged={isForLogged} />
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </ApplicationWrapper>
  );
};

export default memo(AppLayout);
