import { SyntheticEvent, useEffect, useState } from 'react';
import { IconButton, Slider } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import styled, { css } from 'styled-components';
import { Colors } from 'Themes/colors';
import { ListMode, ParticipantListConsts } from '../ParticipantList.consts';
import { ActionsWrapper } from './ListModeAction.styles';

type Props = {
  listMode: ListMode;
  defaultMachineIframeSize: number;
  onListModeChange: (listMode: ListMode) => void;
  onMachineIframeSizeChange: (properties: number) => void;
  onSaveMachineIframeSize: (machineIframeSize: number) => void;
};

const StyledIconButton = styled(IconButton)<{ active: boolean }>`
  ${({ active }) =>
    active
      ? css`
          color: ${Colors.textColor} !important;
        `
      : css`
          color: ${Colors.darkGrey} !important;
        `}

  &:hover {
    color: ${Colors.blue} !important;
  }
`;

const ListModeActions = ({
  listMode,
  defaultMachineIframeSize,
  onListModeChange,
  onMachineIframeSizeChange,
  onSaveMachineIframeSize
}: Readonly<Props>) => {
  const [machineIframeSize, setMachineIframeSize] = useState(6);

  const handleBasicInfoModeClick = () => onListModeChange(ParticipantListConsts.listMode.basicInfo);

  const handleLivePreviewModeClick = () =>
    onListModeChange(ParticipantListConsts.listMode.livePreview);

  const handleChangeMachineIframeSize = (event: Event, value: number | number[]) =>
    changeMachineIframeSize(value as number);

  const handleSaveMachineIframeSize = (event: SyntheticEvent | Event, value: number | number[]) => {
    if (value !== defaultMachineIframeSize) {
      onSaveMachineIframeSize(value as number);
    }
  };

  const changeMachineIframeSize = (value: number) => {
    setMachineIframeSize(value);
    onMachineIframeSizeChange(value);
  };

  const valueLabelFormat = (value: number) => value.toString();

  useEffect(() => {
    changeMachineIframeSize(defaultMachineIframeSize || machineIframeSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActionsWrapper>
      {listMode === ParticipantListConsts.listMode.livePreview ? (
        <Slider
          value={machineIframeSize}
          min={1}
          step={1}
          max={5}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChange={handleChangeMachineIframeSize}
          onChangeCommitted={handleSaveMachineIframeSize}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
      ) : null}
      <StyledIconButton
        onClick={handleLivePreviewModeClick}
        active={listMode === ParticipantListConsts.listMode.livePreview}
      >
        <GridViewIcon fontSize="large" />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleBasicInfoModeClick}
        active={listMode === ParticipantListConsts.listMode.basicInfo}
      >
        <FormatListBulletedIcon fontSize="large" />
      </StyledIconButton>
    </ActionsWrapper>
  );
};

export default ListModeActions;
