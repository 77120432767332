import React, { memo, useMemo } from 'react';
import { toTimeFormat } from 'Utils/dateFormat';

type Props = {
  date: Date;
};

const TimeInfo = ({ date }: Readonly<Props>) => {
  const timeTomeDisplay = useMemo(() => toTimeFormat(date), [date]);

  return date && <span>{timeTomeDisplay}</span>;
};

export default memo(TimeInfo);
