import { pipe, object } from 'valibot'; // 0.63 kB
import { EmailSchema } from './EmailSchema';
import { PasswordSchema } from './PasswordSchema';
import { booleanRequired, stringRequired } from './schema.utils';

export const RegistrationSchema = pipe(
  object({
    firstName: stringRequired(),
    lastName: stringRequired(),
    email: EmailSchema,
    password: PasswordSchema,
    confirmPassword: PasswordSchema,
    acceptTermsAndPrivacyPolicy: booleanRequired
  })
);
