import { useCallback, useEffect } from 'react';
import { ResetPasswordRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { clearResetPasswordError, resetPassword, selectResetPasswordError } from '../authSlice';
import { ResetPasswordForm } from './ResetPasswordForm';

type Props = {
  hash: string;
};

export function ResetPasswordFormContainer({ hash }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const resetPasswordError = useAppSelector(selectResetPasswordError);

  const handleSubmit = useCallback(
    (values: ResetPasswordRequest) => {
      dispatch(resetPassword({ ...values, hash } as ResetPasswordRequest));
    },
    [dispatch, hash]
  );

  useEffect(() => {
    dispatch(clearResetPasswordError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ResetPasswordForm onSubmit={handleSubmit} error={resetPasswordError} />;
}

export default ResetPasswordFormContainer;
