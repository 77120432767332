import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { AvatarUtils } from 'Common/UserAvatar/Avatar/Avatar.utils';
import { formFieldNames } from './formFieldNames';
import { Avatar } from 'Common/UserAvatar/Avatar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  height: 250px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
`;

type Props = {
  displayName: string;
  onColorClick: (value: string) => void;
};

function ColorPicker({ displayName, onColorClick }: Readonly<Props>) {
  const { t } = useTranslation();

  const initial = AvatarUtils.getAvatarInitials(displayName);

  const selectedColor = useWatch({ name: formFieldNames.avatarColor });

  const handleColorClick = (id: string) => onColorClick(id);

  const colorDropdownOptions = Colors.availableUsersAvatarsColors
    .map(x => ({
      value: x.value,
      label: t(`myAccount.colors.${x.translationKey}`)
    }))
    .map(option => {
      const isSelected = selectedColor === option.value;

      return (
        <>
          <Option key={option.value} onClick={() => handleColorClick(option.value)}>
            <AvatarContainer>
              <Avatar
                color={option.value}
                displayName={isSelected ? initial! : ''}
                shouldBiggerOnHover
              />
            </AvatarContainer>

            {option.label}
          </Option>
        </>
      );
    });

  return <Container>{colorDropdownOptions}</Container>;
}

export default memo(ColorPicker);
