import React, { memo } from 'react';
import { CardContent, Card as MuiCard } from '@mui/material';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

const StyledMuiCard = styled(MuiCard)`
  border-radius: 22px;
`;

function CardRounded({ children }: Readonly<Props>) {
  return (
    <StyledMuiCard variant="outlined">
      <CardContent>{children}</CardContent>
    </StyledMuiCard>
  );
}

export default memo(CardRounded);
