import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Skeleton, Typography } from '@mui/material';
import { styled } from 'styled-components';
import { urls } from 'Router';
import { useAppDispatch, useAppSelector } from 'Store';
import { Link, Logo } from 'Components';
import { StyledLogo } from 'Components/Styled';
import { LoginState, confirmEmailAndActivateAccount, selectLoginState } from 'Auth/authSlice';
import { LanguageBar } from 'AppLayout/LanguageBar';
import {
  getAppConfig,
  selectGoogleSettings,
  selectIsConfigLoading,
  selectShouldShowExternalLoginProviders
} from 'Config/configSlice';
import { BottomContainer } from './BottomContainer';
import { LinksContainer } from './LinksContainer';
import LoginFormContainer from './LoginFormContainer';
import ExternalLoginProviders from './ExternalLoginProviders';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function LoginPage() {
  const location = useLocation();
  const { code } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginState = useAppSelector(selectLoginState);
  const googleSettings = useAppSelector(selectGoogleSettings);
  const isConfigLoading = useAppSelector(selectIsConfigLoading);
  const shouldShowExternalLoginProviders = useAppSelector(selectShouldShowExternalLoginProviders);

  const [emailFromForm, setEmailFromForm] = useState<string>();
  const [confirmEmailAndActivateAccountSuccess, setConfirmEmailAndActivateAccountSuccess] =
    useState<boolean>();

  const handleEmailChange = (email: string) => setEmailFromForm(email);

  useEffect(() => {
    if (loginState === LoginState.LOGGED) {
      navigate(urls.homePage);
    }
  }, [loginState, navigate]);

  useEffect(() => {
    if (code) {
      dispatch(confirmEmailAndActivateAccount(code)).then(() =>
        setConfirmEmailAndActivateAccountSuccess(true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!googleSettings?.clientId) {
      dispatch(getAppConfig());
    }
  }, [googleSettings, dispatch]);

  const renderExternalLoginSection = () => {
    if (!shouldShowExternalLoginProviders && !isConfigLoading) {
      return null;
    }

    if (isConfigLoading) {
      return (
        <Skeleton variant="rounded" animation="wave" width={350} height={30} sx={{ m: '0 auto' }} />
      );
    }

    return (
      <>
        <ExternalLoginProviders shouldShowGoogle={!!googleSettings?.clientId} />
        <OrTextContainer>{t('auth.or')}</OrTextContainer>
      </>
    );
  };

  return (
    <>
      <StyledLogo>
        <Typography noWrap>{t('auth.loginIn')}</Typography>
        <Logo variant="login" />
      </StyledLogo>

      {renderExternalLoginSection()}

      {location.state?.isAfterResetPassword ? (
        <Content>
          <Typography fontWeight="bold">{t('auth.passwordHasBeenChanged')}</Typography>{' '}
          <Typography>{t('auth.logInUsingNewPassword')}</Typography>
        </Content>
      ) : (
        ''
      )}

      {confirmEmailAndActivateAccountSuccess ? (
        <Content>
          <Typography fontWeight="bold">
            {t('auth.emailHasBeenConfirmedAndAccountHasBeenActivated')}
          </Typography>{' '}
          <Typography>{t('auth.youCanNowLogIn')}</Typography>
        </Content>
      ) : (
        ''
      )}

      <LoginFormContainer joinCode={location.state?.joinCode} onEmailChange={handleEmailChange} />

      <BottomContainer>
        <LinksContainer>
          {!location.state?.isAfterResetPassword && !confirmEmailAndActivateAccountSuccess ? (
            <>
              <Link
                to={urls.registrationPage}
                label={t('auth.createAccount')}
                shouldOpenInNewTab={false}
                state={{ joinCode: location.state?.joinCode }}
              />
              <Link
                to={urls.forgotPasswordPage}
                label={t('auth.remindPassword')}
                shouldOpenInNewTab={false}
                state={{ email: emailFromForm }}
              />
            </>
          ) : (
            ''
          )}

          <Link to={urls.treneoSite} label={t('auth.whatIsTreneo')} />
        </LinksContainer>

        <LanguageBar />
      </BottomContainer>
    </>
  );
}

export default LoginPage;
