import MuiAvatar from '@mui/material/Avatar';
import styled from 'styled-components';
import { AvatarConsts } from './Avatar.consts';
import { AvatarUtils } from './Avatar.utils';

type Props = {
  displayName: string;
  color: string;
  size?: string;
  shouldBiggerOnHover?: boolean;
};

const StyledMuiAvatar = styled(MuiAvatar)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Avatar = ({
  displayName,
  color,
  size = AvatarConsts.defaultAvatarSize,
  shouldBiggerOnHover = false
}: Readonly<Props>) => {
  const initial = AvatarUtils.getAvatarInitials(displayName);
  // TODO:
  // const hash = AvatarUtils.generateHashFromText(displayName);
  // const avatarBackgroundHex = AvatarUtils.generateHexColorFromHash(hash);

  return (
    <StyledMuiAvatar
      sx={[
        { backgroundColor: color, height: size, width: size },
        shouldBiggerOnHover
          ? () => ({
              '&:hover': {
                transform: 'scale(1.3)',
                transition: 'transform .5s'
              }
            })
          : {}
      ]}
    >
      <div>{initial}</div>
    </StyledMuiAvatar>
  );
};
