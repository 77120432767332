// TODO Vorbert: Create a custom type for extracting type of object values
export type ListMode = keyof typeof ParticipantListConsts.listMode;

export namespace ParticipantListConsts {
  export const listMode = {
    basicInfo: 'basicInfo',
    livePreview: 'livePreview'
  } as const;

  export const defaultListMode = listMode.livePreview;
}
