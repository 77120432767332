import { useCallback } from 'react';
import { ForgotPasswordRequest } from 'Api';
import { useAppDispatch } from 'Store';
import { forgotPassword } from '../authSlice';
import { ForgotPasswordForm } from './ForgotPasswordForm';

type Props = {
  email?: string;
};

export function ForgotPasswordFormContainer({ email }: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (values: ForgotPasswordRequest) => {
      dispatch(forgotPassword(values));
    },
    [dispatch]
  );

  return <ForgotPasswordForm onSubmit={handleSubmit} email={email} />;
}

export default ForgotPasswordFormContainer;
