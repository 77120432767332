import { useCallback, useEffect } from 'react';
import { ChangePasswordRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import {
  clearChangePasswordError,
  changePassword,
  selectChangePasswordError,
  selectIsChangePasswordSuccess,
  clearIsChangePasswordSuccess
} from '../authSlice';
import ChangePasswordForm from './ChangePasswordForm';

type Props = {
  onClose: () => void;
};

export function ChangePasswordFormContainer({ onClose }: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const changePasswordError = useAppSelector(selectChangePasswordError);
  const isChangePasswordSuccess = useAppSelector(selectIsChangePasswordSuccess);

  const handleSubmit = useCallback(
    (values: ChangePasswordRequest) => {
      dispatch(changePassword(values));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(clearChangePasswordError());
    dispatch(clearIsChangePasswordSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChangePasswordForm
      onSubmit={handleSubmit}
      onCancel={onClose}
      error={changePasswordError}
      isSubmitSuccess={isChangePasswordSuccess}
    />
  );
}

export default ChangePasswordFormContainer;
