import { Outlet } from 'react-router';
import { AppLayout } from 'AppLayout';

const NotRequireAuth = () => (
  <AppLayout isForLogged={false}>
    <Outlet />
  </AppLayout>
);

export default NotRequireAuth;
