import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopModel } from 'Api';
import { useAppSelector } from 'Store';
import { ChangeStateLoader } from 'Common/ConnectToDesktopButtons/ConnectToDesktopBase/ChangeStateLoader';
import {
  CourseModel,
  selectIsMyDesktopLoading,
  selectIsTrainerDesktopStartPending
} from 'Courses/coursesSlice';
import MachineInfo from './MachineInfo';
import { StyledWrapper, StyledIFrame } from './LivePreview.styles';

type Props = {
  fullscreenModeIsEnabled?: boolean;
  settingsOpened?: boolean;
  course: CourseModel;
  desktop: DesktopModel;
  isParticipantDesktop: boolean;
  isTrainerDesktopPreview: boolean;
  connectToDesktopButton: ReactNode;
};

const LivePreview = ({
  fullscreenModeIsEnabled,
  settingsOpened,
  course,
  desktop,
  isParticipantDesktop,
  isTrainerDesktopPreview,
  connectToDesktopButton
}: Readonly<Props>) => {
  const { t } = useTranslation();

  const isMyDesktopLoading = useAppSelector(state =>
    selectIsMyDesktopLoading(state, course.id as string)
  );

  const isTrainerDesktopStartPending = useAppSelector(state =>
    selectIsTrainerDesktopStartPending(state, course.id!)
  );

  let componentToRender: ReactNode;

  if (isMyDesktopLoading) {
    componentToRender = '';
  } else if (desktop?.isRunning && desktop?.instanceId && !isTrainerDesktopStartPending) {
    componentToRender = (
      <StyledIFrame
        src={
          isParticipantDesktop || isTrainerDesktopPreview
            ? (desktop.viewLink as string)
            : (desktop.link as string)
        }
        fullscreenModeIsEnabled={fullscreenModeIsEnabled}
        settingsOpened={settingsOpened}
      />
    );
  } else if (
    !desktop?.isRunning &&
    ((course.hasFreshStart && course.isFreshStartMachineReadyToClone) || desktop?.instanceId) &&
    !isTrainerDesktopPreview
  ) {
    componentToRender = connectToDesktopButton;
  } else {
    componentToRender = !isTrainerDesktopStartPending ? (
      <MachineInfo
        courseId={course.id!}
        isTrainer={course.isTrainer!}
        isTrainerDesktopPreview={isTrainerDesktopPreview}
        isCourseCompleted={course.isCompleted!}
      />
    ) : (
      <ChangeStateLoader text={t('Connecting')} />
    );
  }

  return (
    <StyledWrapper fullscreenModeIsEnabled={fullscreenModeIsEnabled}>
      {componentToRender}
    </StyledWrapper>
  );
};

export default LivePreview;
