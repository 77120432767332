import styled from 'styled-components';
import logoDark from 'Images/logoDark.svg';
import logoLight from 'Images/logoWhite.svg';

export type Variant = 'banner' | 'footer' | 'menu' | 'login';

const height: Record<Variant, number> = {
  banner: 98,
  footer: 25,
  menu: 22,
  login: 30
};

const StyledImg = styled.img``;

type Props = {
  variant: Variant;
  color?: 'dark' | 'light';
};

export function Logo({ variant, color = 'light' }: Readonly<Props>) {
  return (
    <StyledImg
      // @ts-ignore
      height={height[variant]} //todo not working ?
      src={color === 'dark' ? logoDark : logoLight}
      alt="logo"
    />
  );
}
