import { memo } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from 'Themes/colors';
import UserChatHead from 'Assets/images/UserChatHead.svg';
import { Chip } from './Chip';

const headSize = '55px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 35px;
`;

const CloudContent = styled.div`
  background: ${Colors.white};
  position: relative;
  display: flex;
  padding: 25px 72px 25px 25px;
  border-radius: 22px;
  align-items: flex-start;
  gap: 21px;
  border: 1px solid ${Colors.lineGrey};

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100%));
  }

  &:before {
    border: 20px solid;
    border-color: ${Colors.lineGrey} ${Colors.lineGrey} transparent transparent;
    right: ${headSize};
  }

  &:after {
    right: calc(${headSize} + 1px);
    border: 19px solid;
    border-color: ${Colors.white} ${Colors.white} transparent transparent;
  }
`;

const Close = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
`;

const Head = styled.img`
  height: ${headSize};
  width: ${headSize};
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

type Props = {
  number: number;
  text: string;
  children?: React.ReactNode;
  onClose: () => void;
};

function ChatCloud({ number, text, children, onClose }: Readonly<Props>) {
  return (
    <Container>
      <CloudContent>
        <Chip color={Colors.red}>{number}</Chip>
        <InnerContent>
          <Typography fontStyle="italic">{text}</Typography>
          {children}
        </InnerContent>
        <Close onClick={onClose} />
      </CloudContent>
      <Head src={UserChatHead}></Head>
    </Container>
  );
}

export default memo(ChatCloud);
