import { useCallback, useContext, useEffect } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { useAppDispatch, useAppSelector } from 'Store';
import { SignalRConnectionContext, useOnSignalRMethod } from 'SignalR';
import {
  selectCoursesIds,
  courseTrainerAllowedToPreview,
  isFreshStartMachineReadyToClone
} from 'Courses/coursesSlice';

export function useJoinCoursesListGroup() {
  const { connection, state } = useContext(SignalRConnectionContext);
  const dispatch = useAppDispatch();
  const courseIds = useAppSelector(selectCoursesIds);

  useOnSignalRMethod(
    'CourseTrainerAllowedToPreview',
    useCallback(
      (courseId: string, value: boolean) => {
        dispatch(courseTrainerAllowedToPreview({ courseId, value }));
      },
      [dispatch]
    )
  );

  useOnSignalRMethod(
    'IsFreshStartMachineReadyToClone',
    useCallback(
      (courseId: string, value: boolean) => {
        dispatch(isFreshStartMachineReadyToClone({ courseId, value }));
      },
      [dispatch]
    )
  );

  // TODO: maybe check connection and connect again if needed?
  useEffect(() => {
    if (state !== HubConnectionState.Connected) {
      return;
    }

    if (courseIds.length === 0) {
      return;
    }

    const joinCoursesListGroup = async () => {
      await connection.invoke('JoinCoursesListGroup', courseIds);
    };

    joinCoursesListGroup().catch(console.error);

    return () => {
      connection.invoke('LeaveCoursesListGroup', courseIds).catch(console.error);
    };
  }, [connection, courseIds, state]);
}
