import { memo } from 'react';
import styled, { css } from 'styled-components';
import { CircleImageConsts } from './CircleImage.consts';

type Props = {
  size?: string | number;
};

const CircleImage = styled.img<Props>`
  display: block;
  border-radius: 50%;
  object-fit: cover;

  ${({ size }) => css`
    width: ${size};
    height: ${size};
  `};
`;

CircleImage.defaultProps = {
  size: CircleImageConsts.defaultSize
};

export default memo(CircleImage);
