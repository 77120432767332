import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Typo } from 'Themes/typo';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import loginBaner from 'Images/loginBanner.png';
import loginBackground from 'Images/loginBackground.jpeg';
import { Card, Logo } from 'Components';

const MainGrid = styled.div`
  background-image: url(${loginBackground});
  min-height: 100svh;
  display: flex;
  align-content: center;
  align-items: center;
`;

const InnerGrid = styled.div`
  padding: ${Spacing.large};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${Spacing.xlarge};
  flex: 1;
  flex-wrap: wrap;
`;

const SideBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacing.medium};
`;

const Banner = styled.img`
  max-width: 415px;
`;

const StyledCard = styled(Card)`
  flex: 1;
  max-width: 525px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: ${Spacing.large};
`;

const StyledBannerTittle = styled(Typography)`
  max-width: 300px;
  text-align: center;
`;

export function AuthLayoutRoute() {
  const { t } = useTranslation();

  return (
    <MainGrid>
      <InnerGrid>
        <StyledCard>
          <Container>
            <Outlet />
          </Container>
        </StyledCard>

        <SideBanner>
          <Banner src={loginBaner} />
          <Logo color="dark" variant="login" />
          <StyledBannerTittle variant={Typo.header} color={Colors.white}>
            {t('auth.bannerTittle')}
          </StyledBannerTittle>
        </SideBanner>
      </InnerGrid>
    </MainGrid>
  );
}

export default AuthLayoutRoute;
