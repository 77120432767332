import { memo, useCallback, useEffect, useState } from 'react';
import { FormContainer as HookFormContainer, SelectElement, useForm } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { Typo } from 'Themes/typo';
import { Spacing } from 'Themes/spacing';
import {
  coursesApi,
  CreateFreshStartFromOsTemplateRequest,
  CreateFreshStartFromStandaloneTemplateRequest,
  SaveIsFreshStartMachineReadyToCloneRequest,
  Standalone
} from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { getAppConfig, selectFreshStartFormDefaultValues } from 'Config/configSlice';
import { translateErrorMessage } from 'Common/Form/FormConsts';
import { Card } from 'Components';
import {
  createFreshStartFromOsTemplate,
  createFreshStartFromStandaloneTemplate,
  getFreshStartDesktop,
  getTrainerDesktop,
  saveIsFreshStartMachineReadyToClone
} from 'Courses/coursesSlice';
import { Divider } from '../Common/Divider';
import OsSelectElement from './OsSelectElement';
import {
  cpyFormValues,
  diskSizeFormValues,
  DropdownOptions,
  memoryFormValues
} from './DropdownOptions.type';
import { formFieldNames } from './formFieldNames';

const FormContainer = styled.div`
  display: flex;
  gap: ${Spacing.large};
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 25px;
`;

const CreateButton = styled(Button)`
  align-self: flex-end;
`;

const formFieldValues = {
  memory: memoryFormValues,
  cpu: cpyFormValues,
  diskSize: diskSizeFormValues
} as const;

const shouldDisplayLabelInDropdown = false;

type Props = {
  courseId: string;
};

function FreshStartSettingsForm({ courseId }: Readonly<Props>) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const freshStartFormDefaultValues = useAppSelector(selectFreshStartFormDefaultValues);

  const formFieldDefaultValues = {
    [formFieldNames.serverName]: freshStartFormDefaultValues.serverName,
    [formFieldNames.memory]: memoryFormValues.find(
      option => freshStartFormDefaultValues.memory === option.label
    )?.id,
    [formFieldNames.cpu]: cpyFormValues.find(
      option => freshStartFormDefaultValues.cpu === option.label
    )?.id,
    [formFieldNames.diskSize]: diskSizeFormValues.find(
      option => freshStartFormDefaultValues.diskSize === option.label
    )?.id
  };

  // TODO: fix as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formContext = useForm<any>({
    mode: 'all',
    // TODO: fix as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: formFieldDefaultValues as any
  });

  const [serverNamesOptions, setServerNamesOptions] = useState<DropdownOptions>();
  const [osTemplateNamesOptions, setOsTemplateNamesOptions] = useState<DropdownOptions>();
  const [standalonesOptions, setStandalonesOptions] = useState<DropdownOptions>();
  const [standalones, setStandalones] = useState<Standalone[]>();
  const [areDropdownsDisabled, setAreDropdownsDisabled] = useState<boolean>(false);

  const getServerNamesOptions = useCallback(() => {
    coursesApi.coursesGetFreshStartServersForCourseGet(courseId).then(data => {
      setServerNamesOptions(mapToDropdownOptions(data.data.serverNames));
      formContext.setValue(formFieldNames.serverName, freshStartFormDefaultValues.serverName);
    });
  }, [courseId, formContext, freshStartFormDefaultValues.serverName]);

  const handleServerNameChange = useCallback(
    (serverName: string) => {
      coursesApi.coursesGetOsTemplatesOfServerGet(courseId, serverName).then(data => {
        setOsTemplateNamesOptions(mapToDropdownOptions(data.data.osTemplateNames));
      });
    },
    [courseId]
  );

  const handleTemplateChange = useCallback(
    (template: string) => {
      const selectedStandalone = standalones?.find(x => x.id === +template);

      if (selectedStandalone) {
        setAreDropdownsDisabled(true);
        formContext.setValue(formFieldNames.serverName, selectedStandalone.serverName);
        formContext.setValue(formFieldNames.memory, selectedStandalone.memory);
        formContext.setValue(formFieldNames.cpu, selectedStandalone.cpu);
        formContext.setValue(formFieldNames.diskSize, selectedStandalone.diskSize);
      } else {
        setAreDropdownsDisabled(false);
        formContext.setValue(formFieldNames.serverName, formFieldDefaultValues.serverName);
        formContext.setValue(formFieldNames.memory, formFieldDefaultValues.memory);
        formContext.setValue(formFieldNames.cpu, formFieldDefaultValues.cpu);
        formContext.setValue(formFieldNames.diskSize, formFieldDefaultValues.diskSize);
      }
    },
    [
      standalones,
      formContext,
      formFieldDefaultValues.serverName,
      formFieldDefaultValues.memory,
      formFieldDefaultValues.cpu,
      formFieldDefaultValues.diskSize
    ]
  );

  const getStandalonesAndStandalonesOptions = useCallback(() => {
    coursesApi.coursesGetStandalonesGet().then(data => {
      setStandalones(data.data.standalones);

      setStandalonesOptions(
        data.data.standalones
          ?.map(standalone => ({
            label: standalone.name as string,
            id: (standalone.id as number).toString()
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    });
  }, []);

  const handleSubmit = useCallback(
    (formData: CreateFreshStartFromOsTemplateRequest) => {
      const onCreateFreshStartSuccess = (): void => {
        dispatch(getFreshStartDesktop(courseId));
        dispatch(getTrainerDesktop(courseId));

        dispatch(
          saveIsFreshStartMachineReadyToClone({
            courseId,
            isFreshStartMachineReadyToClone: true
          } as SaveIsFreshStartMachineReadyToCloneRequest)
        );
      };

      const selectedStandalone = standalonesOptions?.find(x => x.id === formData.template);

      if (!selectedStandalone) {
        dispatch(
          createFreshStartFromOsTemplate({
            courseId,
            serverName: formData.serverName,
            template: formData.template,
            memory: +formData.memory!,
            cpu: +formData.cpu!,
            diskSize: +formData.diskSize!
          } as CreateFreshStartFromOsTemplateRequest)
        ).then(() => onCreateFreshStartSuccess());
      } else {
        dispatch(
          createFreshStartFromStandaloneTemplate({
            courseId,
            standaloneId: formData.template
          } as CreateFreshStartFromStandaloneTemplateRequest)
        ).then(() => onCreateFreshStartSuccess());
      }
    },
    [standalonesOptions, dispatch, courseId]
  );

  const handleTemplateClick = (id: string) => {
    formContext.setValue(formFieldNames.template, id);

    handleTemplateChange(id);
  };

  const mapToDropdownOptions = (items /* TODO: type */): DropdownOptions =>
    items
      ?.map(itemName => ({ label: itemName, id: itemName }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const displayLabel = (label: string): string => (shouldDisplayLabelInDropdown ? label : '');

  useEffect(() => {
    getServerNamesOptions();

    if (freshStartFormDefaultValues.serverName) {
      handleServerNameChange(freshStartFormDefaultValues.serverName);
    } else {
      dispatch(getAppConfig()).then(() => {
        // eslint-disable-next-line no-debugger
        debugger;
        handleServerNameChange(freshStartFormDefaultValues.serverName as string);
      });
    }

    getStandalonesAndStandalonesOptions();
  }, [
    dispatch,
    freshStartFormDefaultValues.serverName,
    getServerNamesOptions,
    getStandalonesAndStandalonesOptions,
    handleServerNameChange
  ]);

  const templatesOptions = [...(osTemplateNamesOptions || []), ...(standalonesOptions || [])];

  return (
    <HookFormContainer onSuccess={handleSubmit} formContext={formContext}>
      <Card>
        <Typography variant={Typo.title}>{t('freshStartSettingsForm.title')}</Typography>

        <FormContainer>
          <Section>
            <Typography fontWeight="bold">
              {t('freshStartSettingsForm.readyComputerTemplates')}
            </Typography>

            <OsSelectElement options={templatesOptions} onTemplateClick={handleTemplateClick} />
          </Section>

          <Divider></Divider>

          <Section>
            <Typography fontWeight="bold">
              {t('freshStartSettingsForm.editTemplateVirtualComputer')}
            </Typography>

            <SelectElement
              label={displayLabel(t('OperationSystem'))}
              name={formFieldNames.template}
              options={templatesOptions}
              required
              parseError={translateErrorMessage}
              onChange={handleTemplateChange}
            />
            <SelectElement
              label={displayLabel(t('ServerName'))}
              name={formFieldNames.serverName}
              options={serverNamesOptions}
              required
              disabled={areDropdownsDisabled}
              parseError={translateErrorMessage}
              onChange={handleServerNameChange}
            />
            <SelectElement
              label={displayLabel(t('Memory'))}
              name={formFieldNames.memory}
              options={formFieldValues.memory}
              required
              disabled={areDropdownsDisabled}
              parseError={translateErrorMessage}
            />
            <SelectElement
              label={displayLabel(t('Processor'))}
              name={formFieldNames.cpu}
              options={formFieldValues.cpu}
              required
              disabled={areDropdownsDisabled}
              parseError={translateErrorMessage}
            />
            <SelectElement
              label={displayLabel(t('DiskSize'))}
              name={formFieldNames.diskSize}
              options={formFieldValues.diskSize}
              required
              disabled={areDropdownsDisabled}
              parseError={translateErrorMessage}
            />

            <CreateButton
              type="submit"
              // TODO: set form as touched after select template or something like this and then uncomment below disabled prop
              // disabled={!formContext.formState.isValid}
              startIcon={<SchoolIcon />}
            >
              {t('freshStartSettingsForm.createComputerFreshStart')}
            </CreateButton>
          </Section>
        </FormContainer>
      </Card>
    </HookFormContainer>
  );
}

export default memo(FreshStartSettingsForm);
