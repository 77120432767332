import React, { memo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import {
  RequireAuth,
  NotRequireAuth,
  AuthLayoutRoute,
  ForgotPasswordPage,
  LoginPage,
  RegistrationPage,
  ResetPasswordPage,
  JoinToCoursePage
} from 'Auth';
import { CoursesPage } from 'Courses';
import { CoursePage } from 'Course';
import CourseSettingsPage from 'Course/Settings/CourseSettingsPage';
import NotFoundPage from 'Common/NotFoundPage/NotFoundPage';
import ForbiddenPage from 'Common/ForbiddenPage/ForbiddenPage';
import { urls } from './urls';

const router = createBrowserRouter([
  {
    element: <RequireAuth />,
    children: [
      {
        path: urls.homePage,
        element: <CoursesPage />
      },
      {
        path: urls.coursePage(':id'),
        element: <CoursePage />
      },
      {
        path: urls.courseSettingsPage(':id'),
        element: <CourseSettingsPage />
      }
    ]
  },
  {
    element: <NotRequireAuth />,
    children: [
      {
        path: '/linkedin',
        element: <LinkedInCallback />
      },
      {
        path: urls.forbiddenPage,
        element: <ForbiddenPage />
      },
      {
        path: urls.notFoundPage,
        element: <NotFoundPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  },
  {
    element: <AuthLayoutRoute />,
    children: [
      {
        path: urls.loginPage,
        element: <LoginPage />
      },
      {
        path: urls.registrationPage,
        element: <RegistrationPage />
      },
      {
        path: urls.confirmEmailAndActivateAccountPage(':code'),
        element: <LoginPage />
      },
      {
        path: urls.forgotPasswordPage,
        element: <ForgotPasswordPage />
      },
      {
        path: urls.resetPasswordPage(':hash'),
        element: <ResetPasswordPage />
      },
      {
        path: urls.joinToCoursePage(':joinCode'),
        element: <JoinToCoursePage shouldSkipConfirmation={false} />
      }
    ]
  }
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
