import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import computer_connection_unavailable from 'Assets/images/computer_connection_unavailable.svg';
import { urls } from 'Router';
import { Link } from 'Components';
import { StyledFullInlineMessage } from 'Components/Styled';
import { ContentWrapper, InfoText, MissingPreviewLinkIcon } from './MachineInfo.styles';

type Props = {
  courseId: string;
  isTrainer: boolean;
  isTrainerDesktopPreview: boolean;
  isCourseCompleted: boolean;
};

const MachineInfo = ({
  courseId,
  isTrainer = false,
  isTrainerDesktopPreview = false,
  isCourseCompleted
}: Readonly<Props>) => {
  const { t } = useTranslation();

  let textToRender: ReactNode;

  if (isTrainerDesktopPreview) {
    textToRender = <div>{t('coursesPage.trainerMachineIsNotStarted')}</div>;
  } else if (isCourseCompleted) {
    textToRender = <div>{t('coursesPage.computerNotAvailable')}</div>;
  } else if (isTrainer) {
    textToRender = (
      <>
        <div>{t('coursesPage.computerNotAvailable')}</div>

        <StyledFullInlineMessage>
          {t('coursesPage.prepareVirtualComputerTemplateInTrainingSettings')}{' '}
          <Link
            to={urls.courseSettingsPage(courseId)}
            label={t('coursesPage.trainingSettings')}
            shouldOpenInNewTab={false}
          />
        </StyledFullInlineMessage>
      </>
    );
  } else {
    textToRender = (
      <>
        <div>{t('coursesPage.computerNotAvailable')}</div>
        <div>{t('coursesPage.waitForTrainerToPrepareComputer')}</div>
      </>
    );
  }

  const messageToRender = (
    <>
      <MissingPreviewLinkIcon
        src={computer_connection_unavailable}
        alt="computer_connection_unavailable"
      />

      <InfoText>{textToRender}</InfoText>
    </>
  );
  // }

  return <ContentWrapper>{messageToRender}</ContentWrapper>;
};

export default MachineInfo;
