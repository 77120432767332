import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { urls } from 'Router';
import { Link, Logo } from 'Components';
import { StyledLogo } from 'Components/Styled';
import { LanguageBar } from 'AppLayout/LanguageBar';
import { BottomContainer } from '../LoginPage/BottomContainer';
import { LinksContainer } from '../LoginPage/LinksContainer';
import ResetPasswordFormContainer from './ResetPasswordFormContainer';
import { Typo } from 'Themes/typo';

export function ResetPasswordPage() {
  const { hash } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <StyledLogo>
        <Typography noWrap>{t('auth.welcomeIn')}</Typography>
        <Logo variant="login" />
      </StyledLogo>

      <Typography variant={Typo.bigHeader} style={{ alignSelf: 'center' }}>
        {t('auth.resetPassword')}
      </Typography>

      <ResetPasswordFormContainer hash={hash!} />

      <BottomContainer>
        <LinksContainer>
          <Stack direction="row" gap="5px">
            <Typography noWrap>{t('auth.rememberPassword')}</Typography>
            <Link to={urls.loginPage} label={t('auth.login')} shouldOpenInNewTab={false} />
          </Stack>
          <Link
            to={urls.registrationPage}
            label={t('auth.createAccount')}
            shouldOpenInNewTab={false}
          />
          <Link to={urls.treneoSite} label={t('auth.whatIsTreneo')} />
        </LinksContainer>

        <LanguageBar />
      </BottomContainer>
    </>
  );
}

export default ResetPasswordPage;
