import { TextFieldElementProps } from 'react-hook-form-mui';
import { useTranslation } from 'react-i18next';
import TextField from './TextField';

type Props = TextFieldElementProps & {
  name: string;
  label?: string;
};

export function PasswordField({ name, label, ...props }: Readonly<Props>) {
  const { t } = useTranslation();

  return (
    <TextField
      {...props}
      type="password"
      name={name}
      label={label || t('auth.password')}
      isRequired
    />
  );
}

export default PasswordField;
