import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Skeleton, Switch, Typography } from '@mui/material';
import {
  DesktopModel,
  SaveIsFreshStartMachineReadyToCloneRequest,
  SaveWhetherTrainerAllowedPreviewRequest
} from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { Typo } from 'Themes/typo';
import { Colors } from 'Themes/colors';
import { Spacing } from 'Themes/spacing';
import { UserDisplayModel } from 'User/userDisplayModel';
import { useBoolean } from 'Utils/customHooks';
import ConfirmationDialog from 'Common/ConfirmationDialog/ConfirmationDialog';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import { CloseIconButton } from 'Common/CloseIconButton';
import { urls } from 'Router';
import fresh_start_avatar_icon from 'Assets/images/fresh_start_avatar_icon.svg';
import { Card as BaseCard } from 'Components';
import { saveAreTipsHidden, selectCurrentUserInfo, selectUserDisplayName } from 'User/userSlice';
import {
  CourseModel,
  saveIsFreshStartMachineReadyToClone,
  saveWhetherTrainerAllowedPreview,
  selectIsFreshStartDesktopLoading,
  selectIsMyDesktopLoading,
  stopFreshStartDesktop
} from 'Courses/coursesSlice';
import LivePreviewCard from 'Course/LivePreviewCard';
import { Divider } from '../Common/Divider';
import ChatCloud from './ChatCloud';
import { Chip } from './Chip';
import { LoginState, selectLoginState } from 'Auth/authSlice';

const Container = styled.div`
  display: flex;
  gap: ${Spacing.large};
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 25px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  gap: 15px;
  border: 1px solid ${Colors.darkGrey};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const InfoChip = styled(Chip)`
  cursor: pointer;
`;

const Card = styled(BaseCard)`
  gap: 46px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LivePreviewContainer = styled.div<{ areTipsHidden?: boolean }>`
  ${({ areTipsHidden }) =>
    !areTipsHidden &&
    css`
      margin-top: -6rem;
      margin-bottom: ${Spacing.large};
    `}
`;

const StyledCloseIconButton = styled(CloseIconButton)`
  position: absolute !important;
  top: ${Spacing.small};
  right: ${Spacing.small};
  background: red;
`;

type Props = {
  course: CourseModel;
};

function Settings({ course }: Readonly<Props>) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [stopFreshStartDialogIsDisplayed, displayStopFreshStartDialog, hideStopFreshStartDialog] =
    useBoolean();

  const isFreshStartDesktopLoading = useAppSelector(state =>
    selectIsFreshStartDesktopLoading(state, course.id as string)
  );

  const isMyDesktopLoading = useAppSelector(state =>
    selectIsMyDesktopLoading(state, course.id as string)
  );

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUserInfoDisplayName =
    useAppSelector(selectUserDisplayName) || AvatarConsts.fallbackUserName;

  const currentUserModel = {
    displayName: currentUserInfoDisplayName,
    avatarColor: currentUserInfo.avatarColor,
    avatar: currentUserInfo.avatar
  } as UserDisplayModel;

  const loginState = useAppSelector(selectLoginState);
  const isLogged = loginState === LoginState.LOGGED;

  const handleSaveWhetherTrainerAllowedPreviewChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        saveWhetherTrainerAllowedPreview({
          courseId: course.id,
          whetherTrainerAllowedPreview: event.target.checked
        } as SaveWhetherTrainerAllowedPreviewRequest)
      );
    },
    [course.id, dispatch]
  );

  const showTips = () => handleSaveAreTipsHiddenChange(false);
  const hideTips = () => handleSaveAreTipsHiddenChange(true);

  const handleSaveAreTipsHiddenChange = useCallback(
    (value: boolean) => {
      dispatch(
        saveAreTipsHidden({
          areTipsHidden: value,
          isLogged
        })
      );
    },
    [dispatch, isLogged]
  );

  const handleSaveIsFreshStartMachineReadyToCloneChange = useCallback(
    (value: boolean) => {
      dispatch(
        saveIsFreshStartMachineReadyToClone({
          courseId: course.id,
          isFreshStartMachineReadyToClone: value
        } as SaveIsFreshStartMachineReadyToCloneRequest)
      );

      hideStopFreshStartDialog();
    },
    [course.id, dispatch, hideStopFreshStartDialog]
  );

  const handleStopFreshStartMachine = () => {
    dispatch(
      stopFreshStartDesktop({
        courseId: course.id as string,
        desktopInstanceId: course?.freshStartDesktop?.instanceId as number
      })
    );
  };

  const onSaveIsFreshStartMachineReadyToCloneChange = useCallback(() => {
    if (course?.isFreshStartMachineReadyToClone) {
      handleSaveIsFreshStartMachineReadyToCloneChange(false);
    } else if (course?.freshStartDesktop?.isRunning) {
      displayStopFreshStartDialog();
    } else {
      handleSaveIsFreshStartMachineReadyToCloneChange(true);
    }
  }, [
    course?.freshStartDesktop?.isRunning,
    course?.isFreshStartMachineReadyToClone,
    displayStopFreshStartDialog,
    handleSaveIsFreshStartMachineReadyToCloneChange
  ]);

  const freshStartMachineIsReadyToCloneSwitch = (
    <SwitchContainer>
      <Switch
        size="small"
        checked={course.isFreshStartMachineReadyToClone}
        onChange={onSaveIsFreshStartMachineReadyToCloneChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>{t('FreshStartMachineIsReadyToClone')}</Typography>
    </SwitchContainer>
  );

  const sharePreviewOfMyMachineSwitch = (
    <SwitchContainer>
      <Switch
        size="small"
        checked={course.whetherTrainerAllowedPreview}
        onChange={handleSaveWhetherTrainerAllowedPreviewChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography>{t('SharePreviewOfMyMachine')}</Typography>
    </SwitchContainer>
  );

  const navigate = useNavigate();

  return (
    <>
      <Card>
        <TitleContainer>
          <Typography variant={Typo.title}>{t('courseSettings.title')}</Typography>
          {currentUserInfo?.areTipsHidden && (
            <InfoChip color={Colors.blue} onClick={showTips}>
              i
            </InfoChip>
          )}
        </TitleContainer>

        <Container>
          <Section>
            {!currentUserInfo?.areTipsHidden && (
              <ChatCloud
                number={1}
                text={t('courseSettings.descriptionChatCloud1')}
                onClose={hideTips}
              />
            )}

            {isFreshStartDesktopLoading ? (
              <Skeleton variant="rounded" animation="wave" width={220} height={33} key={0} />
            ) : (
              <LivePreviewContainer areTipsHidden={currentUserInfo?.areTipsHidden}>
                <LivePreviewCard
                  staticSize={!currentUserInfo?.areTipsHidden}
                  course={course}
                  user={
                    {
                      displayName: t('FreshStart'),
                      customAvatar: (
                        <img src={fresh_start_avatar_icon} alt="fresh start avatar icon" />
                      )
                    } as UserDisplayModel
                  }
                  desktop={course.freshStartDesktop as DesktopModel}
                  isFreshStartDesktop
                />
              </LivePreviewContainer>
            )}

            {!currentUserInfo?.areTipsHidden ? (
              <ChatCloud
                number={2}
                text={t('courseSettings.descriptionChatCloud2')}
                onClose={hideTips}
              >
                {freshStartMachineIsReadyToCloneSwitch}
              </ChatCloud>
            ) : (
              freshStartMachineIsReadyToCloneSwitch
            )}
          </Section>

          <Divider />

          <Section>
            {!currentUserInfo?.areTipsHidden && (
              <ChatCloud
                number={3}
                text={t('courseSettings.descriptionChatCloud3')}
                onClose={hideTips}
              />
            )}

            {isMyDesktopLoading ? (
              <Skeleton variant="rounded" animation="wave" width={220} height={33} key={0} />
            ) : (
              <LivePreviewContainer areTipsHidden={currentUserInfo?.areTipsHidden}>
                <LivePreviewCard
                  staticSize={!currentUserInfo?.areTipsHidden}
                  course={course}
                  user={currentUserModel}
                  desktop={course.myDesktop as DesktopModel}
                  isTrainerDesktop
                  isHighlighted
                />
              </LivePreviewContainer>
            )}

            {!currentUserInfo?.areTipsHidden ? (
              <ChatCloud
                number={4}
                text={t('courseSettings.descriptionChatCloud4')}
                onClose={hideTips}
              >
                {sharePreviewOfMyMachineSwitch}
              </ChatCloud>
            ) : (
              sharePreviewOfMyMachineSwitch
            )}
          </Section>
        </Container>
        <StyledCloseIconButton onClose={() => navigate(urls.coursePage(course.id as string))} />
      </Card>

      <ConfirmationDialog
        open={stopFreshStartDialogIsDisplayed}
        content={`${t('StopFreshStartWarning')} ${t('AreYouSure')}`}
        onNo={hideStopFreshStartDialog}
        onYes={() => {
          handleSaveIsFreshStartMachineReadyToCloneChange(true);
          handleStopFreshStartMachine();
        }}
      />
    </>
  );
}

export default memo(Settings);
