import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getCourse, getFreshStartDesktop, getMyDesktop, selectCourse } from 'Courses/coursesSlice';
import { breadcrumbs as coursesBreadcrumbs } from 'Courses/CoursesPage';
import { useAppDispatch, useAppSelector } from 'Store';
import { useBreadcrumbs } from 'Breadcrumb';
import { urls } from 'Router';
import { FreshStartSettingsForm } from './FreshStartSettingsForm';
import { Settings } from './Settings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function CourseSettingsPage() {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const course = useAppSelector(state => selectCourse(state, id as string));

  const items = useMemo(
    () => [
      ...coursesBreadcrumbs,
      {
        label: course?.title,
        to: urls.coursePage(id as string)
      },
      { translateKey: 'coursePanel.courseSettings', to: '/' }
    ],
    [id, course?.title]
  );

  useBreadcrumbs(items);

  useEffect(() => {
    const courseId = id as string;

    if (!course) {
      dispatch(getCourse(courseId)).then(() => {
        dispatch(getMyDesktop(courseId));
        dispatch(getFreshStartDesktop(courseId));
      });
    } else {
      !course.myDesktop && dispatch(getMyDesktop(courseId));
      !course.freshStartDesktop && dispatch(getFreshStartDesktop(courseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {course ? (
        <>
          {!course?.hasFreshStart ? (
            <FreshStartSettingsForm courseId={id!} />
          ) : (
            <Settings course={course!} />
          )}
        </>
      ) : (
        'loading'
      )}

      {/* {!course?.hasFreshStart ? (
        <FreshStartSettingsForm courseId={id!} />
      ) : (
        <Settings course={course!} />
      )} */}
    </Container>
  );
}

export default memo(CourseSettingsPage);
