import { Card, IconButton, Stack } from '@mui/material';
import type { CardProps } from '@mui/material';
import { styled, css } from '@mui/system';
import { Colors } from 'Themes/colors';
import { actionPanelHeight } from './ActionPanelHeight';

export const ActionWrapper = styled(Stack)<{
  fullScreen: boolean;
  pinEnabled: boolean;
  isMinimized: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;

  z-index: 10;
  height: ${actionPanelHeight};

  ${({ isMinimized }) =>
    !isMinimized &&
    css`
      border-top: 1px solid ${Colors.darkGrey};
    `}

  ${({ fullScreen, pinEnabled }) =>
    fullScreen &&
    css`
      border-radius: 0 0 22px 22px;
      position: fixed;
      top: 0;
      left: 50%;
      background: ${Colors.white};
      transform: translateX(-50%);
      transition: top 0.3s ease-in-out;

      ${!pinEnabled &&
      css`
        top: calc(-${actionPanelHeight} + 5px);

        &:hover {
          top: 0;
          opacity: 1;
        }
      `}
    `}
`;

type Props = CardProps & {
  fullscreenModeIsEnabled?: boolean;
  staticSize?: boolean;
  settingsOpened?: boolean;
  isMinimized?: boolean;
};

export const StyledCard = styled(Card)<Props>`
  padding: 0;
  flex: 1;
  border-radius: 22px;
  background: ${Colors.white};
  position: relative;

  ${({ settingsOpened }) =>
    settingsOpened
      ? css`
          outline: 2px solid ${Colors.black};
        `
      : css`
          outline: 1px solid ${Colors.darkGrey};
        `}

  ${({ staticSize }) =>
    staticSize &&
    css`
      max-width: 298px;
      max-height: 222px;
    `}

  ${({ fullscreenModeIsEnabled }) =>
    fullscreenModeIsEnabled &&
    css`
      display: flex;
      flex-direction: column;
      border-radius: 0;
    `}

  ${({ isMinimized }) =>
    !isMinimized &&
    css`
      aspect-ratio: 298/222;
    `}
`;

export const PushPinButton = styled(IconButton)<{ pinEnabled: boolean }>`
  ${({ pinEnabled }) =>
    pinEnabled &&
    css`
      svg {
        color: ${Colors.blue};
      }
    `}
`;
