import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'Store';
import { openExternalUrl } from 'Utils/redirects';
import {
  connectToMyDesktop,
  getMyDesktop,
  selectIsMyDesktopDeletePending,
  selectIsMyDesktopStartPending,
  selectIsMyDesktopStopPending,
  selectMyDesktopLink
} from 'Courses/coursesSlice';
import { ConnectToDesktopButton } from './ConnectToDesktopBase';

type Props = {
  courseId: string;
  disabled?: boolean;
  isHighlighted: boolean;
  isMinimized: boolean;
};

const ConnectToMyDesktopButton = ({
  courseId,
  disabled,
  isHighlighted,
  isMinimized
}: Readonly<Props>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isMyDesktopStartPending = useAppSelector(state =>
    selectIsMyDesktopStartPending(state, courseId)
  );

  const isMyDesktopStopPending = useAppSelector(state =>
    selectIsMyDesktopStopPending(state, courseId)
  );

  const isMyDesktopDeletePending = useAppSelector(state =>
    selectIsMyDesktopDeletePending(state, courseId)
  );

  const myDesktopLink = useAppSelector(state => selectMyDesktopLink(state, courseId));

  const redirectToDesktop = (desktopLink: string) => openExternalUrl(desktopLink);

  const shouldNotRedirectToNewTab = true;

  const connectToDesktop = () =>
    dispatch(connectToMyDesktop(courseId)).then(() => {
      // TODO: zmienić nazwę pola z "link" na np. "desktopLink",
      // wtedy sprawdzenie typu nie będzie potrzebne,
      // zrobić analogicznie w innych miejscach w aplikacji, np.ConnectToFreshStartButton itp.
      const isMyDesktopLinkString = typeof myDesktopLink === 'string';

      if (!myDesktopLink || !isMyDesktopLinkString) {
        dispatch(getMyDesktop(courseId)).then(response => {
          const newLink = (response?.payload as any)?.myDesktop?.link; // TODO: any

          !shouldNotRedirectToNewTab && newLink && redirectToDesktop(newLink as string);
        });
      } else {
        !shouldNotRedirectToNewTab &&
          myDesktopLink &&
          isMyDesktopLinkString &&
          redirectToDesktop(myDesktopLink);
      }
    });

  return (
    <ConnectToDesktopButton
      buttonText={t('Connect')}
      onConnectToDesktopClick={connectToDesktop}
      disabled={disabled}
      isDesktopStartPending={isMyDesktopStartPending}
      isDesktopStopPending={isMyDesktopStopPending}
      isDesktopDeletePending={isMyDesktopDeletePending}
      isHighlighted={isHighlighted}
      isMinimized={isMinimized}
    />
  );
};

export default memo(ConnectToMyDesktopButton);
