import React, { memo, useMemo } from 'react';
import { toDateFormat } from 'Utils/dateFormat';

type Props = {
  date: Date;
};

const DateInfo = ({ date }: Readonly<Props>) => {
  const dateToDisplay = useMemo(() => toDateFormat(date), [date]);

  return date && <span>{dateToDisplay}</span>;
};

export default memo(DateInfo);
