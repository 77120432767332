import { Link } from 'react-router';
import { urls } from 'Router';
import { UserProfileDropdown } from 'AppLayout/UserProfileDropdown';
import { Logo } from 'Components';
import { Breadcrumbs } from 'Breadcrumb';
import { LanguageBar } from '../LanguageBar';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { StyledWrapper, NavbarDivider, NavbarSide } from './Navbar.styles';

type Props = {
  isForLogged: boolean;
};

export const Navbar = ({ isForLogged }: Readonly<Props>) => {
  return (
    <StyledWrapper>
      <NavbarDivider>
        <NavbarSide>
          <>
            <Link to={urls.homePage}>
              <Logo variant="menu" />
            </Link>
            <Breadcrumbs />
          </>
        </NavbarSide>

        <NavbarSide>
          {isForLogged ? <UserProfileDropdown /> : ''}
          <LanguageBar />
          <ThemeSwitcher />
        </NavbarSide>
      </NavbarDivider>
    </StyledWrapper>
  );
};
