import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import styled from 'styled-components';
import { Colors } from 'Themes/colors';
import { useToggle } from 'Utils/customHooks';
import { Linkify } from 'Common/Linkify';

const ToggleTypography = styled(Typography)`
  cursor: pointer;
  color: ${Colors.blue} !important;
  font-style: normal;
  display: flex;
  align-items: center;
`;

type Props = {
  value: string | null | undefined;
  shouldShowAllValue?: boolean;
};

const MAX_LENGTH = 200;

function Description({ value, shouldShowAllValue = false }: Readonly<Props>) {
  const { t } = useTranslation();

  const [more, toggle] = useToggle();

  if (!value) {
    return '';
  }

  if (!shouldShowAllValue && value.length > MAX_LENGTH) {
    return (
      <Typography fontStyle="italic">
        <Linkify>{more && !shouldShowAllValue ? value : value.slice(0, MAX_LENGTH)}</Linkify>
        <ToggleTypography onClick={toggle}>
          {!shouldShowAllValue ? (
            more ? (
              <>
                <ArrowDropUpIcon />
                {t('coursesPage.hideText')}
              </>
            ) : (
              <>
                <ArrowDropDownIcon />
                {t('coursesPage.readMore')}
              </>
            )
          ) : (
            ''
          )}
        </ToggleTypography>
      </Typography>
    );
  }

  return (
    <Typography fontStyle="italic">
      <Linkify>{value}</Linkify>
    </Typography>
  );
}

export default memo(Description);
