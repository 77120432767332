import React, { memo } from 'react';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { Colors } from 'Themes/colors';
import UserChatHead from 'Assets/images/UserChatHead.svg';

const StyledIconButton = styled(IconButton)`
  margin: -14px !important;
`;

const Content = styled.div`
  display: flex;
  gap: ${Spacing.medium};
  flex-direction: column;
`;

const MainContainer = styled.div`
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
  background: ${Colors.white};
  border-radius: 7px;
  display: flex;
  padding: ${Spacing.medium};
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${Spacing.medium};
`;

const headSize = '55px';

const CloudContent = styled.div`
  background: ${Colors.white};
  position: relative;
  display: flex;
  padding: 25px 72px 25px 25px;
  border-radius: 22px;
  align-items: flex-start;
  gap: 21px;
  border: 1px solid ${Colors.textColor};

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100%));
  }

  &:before {
    border: 20px solid;
    border-color: ${Colors.textColor} transparent transparent ${Colors.textColor};
    left: calc(1.2 * ${headSize});
  }

  &:after {
    left: calc(1.2 * ${headSize} + 1px);
    border: 19px solid;
    border-color: ${Colors.white} transparent transparent ${Colors.white};
  }
`;

const Head = styled.img`
  height: ${headSize};
  width: ${headSize};
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.xlarge};
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.medium};
`;

type Props = {
  content: React.ReactNode;
  buttons: React.ReactNode;
  onClose: () => void;
  open: DialogProps['open'];
};

function HeadDialog({ content, buttons, onClose, open }: Readonly<Props>) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <MainContainer>
        <Content>
          <CloudContent>{content}</CloudContent>
          <BottomContent>
            <Head src={UserChatHead}></Head>
            <Buttons>{buttons}</Buttons>
          </BottomContent>
        </Content>
        <StyledIconButton onClick={onClose}>
          <CloseIcon fontSize="medium" />
        </StyledIconButton>
      </MainContainer>
    </Dialog>
  );
}

export default memo(HeadDialog);
