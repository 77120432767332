import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'Store';
import { Spacing } from 'Themes/spacing';
import SearchBar from 'Common/SearchBar/SearchBar';
import { AvatarConsts } from 'Common/UserAvatar/Avatar/Avatar.consts';
import { BreadcrumbItem, useBreadcrumbs } from 'Breadcrumb';
import {
  selectCurrentUserInfo,
  selectIsStartBannerHidden,
  selectUserDisplayName
} from 'User/userSlice';
import { UserDisplayModel } from 'User/userDisplayModel';
import {
  CourseModel,
  getCourseDesktops,
  getCourses,
  getMyDesktop,
  selectAreCoursesLoading,
  selectCompletedCourses,
  selectDemoCourse,
  selectOngoingCourses,
  selectScheduledCourses
} from './coursesSlice';
import CourseCard from './CourseCard';
import { CoursesGroup } from './CoursesGroup';
import StartBanner from './StartBanner';
import CoursesEmpty from './CoursesEmpty';
import { GridWithRestrictedSize } from './CoursesPage.styles';
import { useJoinCoursesListGroup } from './data/hooks/useJoinCoursesListGroup';

const NoResults = styled(Typography)`
  width: 100%;
  text-align: center;
`;

export const breadcrumbs: BreadcrumbItem[] = [{ translateKey: 'coursesPage.courses', to: '/' }];

export function CoursesPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useJoinCoursesListGroup();
  useBreadcrumbs(breadcrumbs);

  const areCoursesLoading = useAppSelector(selectAreCoursesLoading);

  const scheduledCourses = useAppSelector(selectScheduledCourses);
  const ongoingCourses = useAppSelector(selectOngoingCourses);
  const completedCourses = useAppSelector(selectCompletedCourses);
  const demoCourse = useAppSelector(selectDemoCourse);

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUserInfoDisplayName =
    useAppSelector(selectUserDisplayName) || AvatarConsts.fallbackUserName;

  const currentUserModel = {
    displayName: currentUserInfoDisplayName,
    avatarColor: currentUserInfo.avatarColor,
    avatar: currentUserInfo.avatar
  } as UserDisplayModel;

  const [scheduledCoursesFiltered, setScheduledCoursesFiltered] =
    useState<CourseModel[]>(scheduledCourses);

  const [ongoingCoursesFiltered, setOngoingCoursesFiltered] =
    useState<CourseModel[]>(ongoingCourses);

  const [completedCoursesFiltered, setCompletedCoursesFiltered] =
    useState<CourseModel[]>(completedCourses);

  const [query, setQuery] = useState('');

  const [areCoursesLoaded, setAreCoursesLoaded] = useState(false);

  const filterCoursesByTitle = useCallback(
    (courses: CourseModel[]) =>
      courses.filter(x => x.title?.toLowerCase().includes(query.toLowerCase())),
    [query]
  );

  useEffect(() => {
    if (!query) {
      setScheduledCoursesFiltered(scheduledCourses);
      setOngoingCoursesFiltered(ongoingCourses);
      setCompletedCoursesFiltered(completedCourses);
    }

    setTimeout(() => {
      if (query) {
        setScheduledCoursesFiltered(filterCoursesByTitle(scheduledCourses));
        setOngoingCoursesFiltered(filterCoursesByTitle(ongoingCourses));
        setCompletedCoursesFiltered(filterCoursesByTitle(completedCourses));
      }
    }, 200);
  }, [query, scheduledCourses, ongoingCourses, completedCourses, filterCoursesByTitle]);

  useEffect(() => {
    if (areCoursesLoaded) {
      if (demoCourse) {
        dispatch(getCourseDesktops(demoCourse.id as string));
      }

      if (scheduledCourses?.length) {
        for (const course of scheduledCourses) {
          dispatch(getCourseDesktops(course.id as string));
        }
      }

      if (ongoingCourses?.length) {
        for (const course of ongoingCourses) {
          dispatch(getCourseDesktops(course.id as string));
        }
      }

      if (completedCourses?.length) {
        for (const course of completedCourses) {
          dispatch(getMyDesktop(course.id as string));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCoursesLoaded]);

  useEffect(() => {
    const courses = [...scheduledCourses, ...ongoingCourses, ...completedCourses];

    if (courses?.length <= 1) {
      setAreCoursesLoaded(false);
      dispatch(getCourses()).then(() => setAreCoursesLoaded(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ongoingCoursesCards = ongoingCoursesFiltered.map((course: CourseModel) => (
    <CourseCard key={course.id} course={course} user={currentUserModel} isOngoing />
  ));

  const scheduledCoursesCards = scheduledCoursesFiltered.map((course: CourseModel) => (
    <CourseCard
      key={course.id}
      course={course}
      user={currentUserModel}
      shouldSkipHighlighted={ongoingCoursesFiltered.some(course => !course.hasFreshStart)}
    />
  ));

  const completedCoursesCards = completedCoursesFiltered.map((course: CourseModel) => (
    <CourseCard key={course.id} course={course} user={currentUserModel} />
  ));

  const isStartBannerHidden = useAppSelector(selectIsStartBannerHidden);

  const allCoursesCards = [
    ...ongoingCoursesCards,
    ...scheduledCoursesCards,
    ...completedCoursesCards
  ];

  const isAnyCourse = [...ongoingCourses, ...scheduledCourses, ...completedCourses]?.length > 0;

  return (
    <>
      {!isStartBannerHidden && (
        <StartBanner
          isHighlighted={!isAnyCourse || !ongoingCoursesCards || ongoingCoursesCards.length === 0}
        />
      )}

      {areCoursesLoading ? (
        <Stack spacing={Spacing.small} sx={{ marginTop: '1rem' }}>
          <Skeleton variant="rounded" animation="wave" width={500} height={50} />
          <Skeleton variant="rounded" animation="wave" width={500} height={50} />
          <Skeleton variant="rounded" animation="wave" width={500} height={50} />
        </Stack>
      ) : isAnyCourse ? (
        <>
          <SearchBar value={query} onChangeText={setQuery} />
          <GridWithRestrictedSize container alignItems="center">
            <Stack spacing={Spacing.xlarge} flex="1">
              {!query ? (
                <>
                  {ongoingCoursesFiltered?.length ? (
                    <CoursesGroup label={t('coursesPage.ongoingTrainings')}>
                      {ongoingCoursesCards}
                    </CoursesGroup>
                  ) : (
                    ''
                  )}

                  {scheduledCoursesFiltered?.length ? (
                    <CoursesGroup label={t('coursesPage.scheduledTrainings')}>
                      {scheduledCoursesCards}
                    </CoursesGroup>
                  ) : (
                    ''
                  )}

                  {completedCoursesFiltered?.length ? (
                    <CoursesGroup label={t('coursesPage.completedTrainings')}>
                      {completedCoursesCards}
                    </CoursesGroup>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <CoursesGroup
                  label={`${t('coursesPage.searchResults')} ${t('coursesPage.for')} "${query}"`}
                  isSearchResultsGroup
                >
                  {!allCoursesCards?.length ? (
                    <NoResults>{t('coursesPage.noResults')}</NoResults>
                  ) : (
                    allCoursesCards
                  )}
                </CoursesGroup>
              )}
            </Stack>
          </GridWithRestrictedSize>
        </>
      ) : (
        <CoursesEmpty />
      )}
    </>
  );
}

export default memo(CoursesPage);
