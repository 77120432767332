import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import styled, { css } from 'styled-components';
import { Colors } from 'Themes/colors';
import { DesktopModel } from 'Api';
import SettingsMenu from './SettingsMenu';

const StyledStack = styled(Stack)<{ settingsOpened: boolean }>`
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;

  ${({ settingsOpened }) =>
    settingsOpened &&
    css`
      & > *:last-child {
        margin-left: 16px;
      }
    `}
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 10px;
  > svg {
    color: ${Colors.green};
  }
`;

type Props = {
  desktop: DesktopModel;
  isFullscreenEnabled: boolean;
  isTrainerDesktopPreview: boolean;
  settingsOpened: boolean;
  toggleSettings: () => void;
  handleOpenPreviewOnNewTab: () => void;
  handleStopMachine: () => void;
  displayDialog: () => void;
  FullscreenIcon: React.ComponentType;
  toggleFullscreenMode: () => void;
};

const ActionButtons = ({
  desktop,
  isFullscreenEnabled,
  isTrainerDesktopPreview,
  settingsOpened,
  toggleSettings,
  handleOpenPreviewOnNewTab,
  handleStopMachine,
  displayDialog,
  FullscreenIcon,
  toggleFullscreenMode
}: Readonly<Props>) => {
  const { t } = useTranslation();

  return (
    <StyledStack settingsOpened={settingsOpened}>
      <Tooltip title={t('FullScreen')}>
        <IconButton size="small" color="primary" onClick={toggleFullscreenMode}>
          <FullscreenIcon />
        </IconButton>
      </Tooltip>

      {!isFullscreenEnabled ? (
        <Tooltip title={t('OpenOnNewTab')}>
          <IconButton
            size="small"
            onClick={handleOpenPreviewOnNewTab}
            disabled={!desktop?.instanceId}
            sx={{ color: Colors.darkGrey }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      {!isTrainerDesktopPreview && !isFullscreenEnabled ? (
        <SettingsMenu disabled={!desktop?.instanceId} open={settingsOpened} toggle={toggleSettings}>
          {desktop?.isRunning && (
            <StyledMenuItem key={0} onClick={handleStopMachine}>
              <RemoveCircleIcon />
              {t('StopMachine')}
            </StyledMenuItem>
          )}

          {desktop?.instanceId && (
            <StyledMenuItem key={1} onClick={displayDialog}>
              <DeleteIcon />
              {t('RemoveMachine', { instanceName: desktop?.instanceName })}
            </StyledMenuItem>
          )}
        </SettingsMenu>
      ) : null}
    </StyledStack>
  );
};

export default ActionButtons;
