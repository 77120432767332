import { Action, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import { axiosMiddleware } from 'Config/axios';
import { configSlice } from 'Config';
import { authSlice } from 'Auth';
import { userSlice } from 'User';
import { coursesSlice } from 'Courses';
import { isNotProductionEnvironment } from 'Utils/environments';
import { breadcrumbReducer } from 'Breadcrumb';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const rootReducer = combineReducers({
  config: configSlice,
  auth: authSlice,
  user: userSlice,
  courses: coursesSlice,
  breadcrumb: breadcrumbReducer
});

const resettableRootReducer: Reducer = (state: RootState, action: Action) => {
  if (action.type === 'auth/logout/pending') {
    storage.removeItem('persist:root');
    Object.keys(state).forEach(key => delete state[key]);
    return rootReducer(state, action);
  }

  return rootReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: persistReducer(persistConfig, resettableRootReducer),
  devTools: isNotProductionEnvironment(),
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['_persist'],
        ignoredActions: ['persist/PERSIST']
      }
    }).concat(axiosMiddleware) as any // TODO: remove any
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
