import React, { memo } from 'react';
import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components';

const StyledCard = styled(MuiCard)`
  padding: 54px;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

function Card({ children, className }: Readonly<Props>) {
  return (
    <StyledCard variant="outlined" className={className}>
      {children}
    </StyledCard>
  );
}

export default memo(Card);
