import { Alert } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
  text: string;
};

const AlertError = ({ text }: Readonly<Props>) => (
  <Alert severity="error" icon={<CancelIcon />}>
    {text}
  </Alert>
);

export default AlertError;
