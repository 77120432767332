import { pipe, minLength, regex } from 'valibot'; // 0.63 k
import {
  passwordMustContainAtLeastOneNumberTranslationKey,
  passwordMustContainAtLeastOneSpecialCharacterTranslationKey,
  passwordMustContainAtLeastOneUppercaseLetterTranslationKey,
  passwordMustHave8CharactersOrMoreTranslationKey,
  stringRequired
} from './schema.utils';

export const PasswordSchema = pipe(
  stringRequired(),
  regex(/[A-Z]/, passwordMustContainAtLeastOneUppercaseLetterTranslationKey),
  regex(/\d/, passwordMustContainAtLeastOneNumberTranslationKey),
  regex(/[^a-zA-Z\d]/, passwordMustContainAtLeastOneSpecialCharacterTranslationKey),
  minLength(8, passwordMustHave8CharactersOrMoreTranslationKey)
);
