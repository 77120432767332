import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { urls } from 'Router';
import { Link, Logo } from 'Components';
import { StyledLogo } from 'Components/Styled';
import { LanguageBar } from 'AppLayout/LanguageBar';
import { BottomContainer } from '../LoginPage/BottomContainer';
import { LinksContainer } from '../LoginPage/LinksContainer';
import ForgotPasswordFormContainer from './ForgotPasswordFormContainer';

export function ForgotPasswordPage() {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <StyledLogo>
        <Typography noWrap>{t('auth.resettingPasswordTo')}</Typography>
        <Logo variant="login" />
      </StyledLogo>

      <Typography noWrap style={{ alignSelf: 'center' }}>
        {t('auth.enterYourEmailToResetPassword')}
      </Typography>

      <ForgotPasswordFormContainer email={location.state?.email} />

      <BottomContainer>
        <LinksContainer>
          <Stack direction="row" gap="5px">
            <Typography noWrap>{t('auth.rememberPassword')}</Typography>
            <Link to={urls.loginPage} label={t('auth.login')} shouldOpenInNewTab={false} />
          </Stack>
          <Link
            to={urls.registrationPage}
            label={t('auth.createAccount')}
            shouldOpenInNewTab={false}
          />
          <Link to={urls.treneoSite} label={t('auth.whatIsTreneo')} />
        </LinksContainer>

        <LanguageBar />
      </BottomContainer>
    </>
  );
}

export default ForgotPasswordPage;
