import { Navigate, Outlet } from 'react-router';
import { urls } from 'Router';
import { useAppSelector } from 'Store';
import { AppLayout } from 'AppLayout';
import { selectLoginState, LoginState } from '../authSlice';

const RequireAuth = () => {
  const loginState = useAppSelector(selectLoginState);

  if (loginState !== LoginState.LOGGED) {
    return <Navigate to={urls.loginPage} />;
  }

  return (
    <AppLayout isForLogged>
      <Outlet />
    </AppLayout>
  );
};

export default RequireAuth;
