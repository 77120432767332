import GoogleSimpleButton from './GoogleSimpleButton';

type Props = {
  readonly shouldShowGoogle: boolean;
};

export function ExternalLoginProviders({ shouldShowGoogle }: Readonly<Props>) {
  return shouldShowGoogle ? <GoogleSimpleButton /> : '';
}

export default ExternalLoginProviders;
