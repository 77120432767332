import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  cursor: pointer;
  & > *:hover {
    text-shadow: 0 0 1px black;
  }
`;

const StyledTypography = styled(Typography)`
  text-wrap: nowrap;
`;

type Props = {
  icon: ReactNode;
  text: string;
  onClick: () => void;
};

function ActionPosition({ icon, text, onClick }: Readonly<Props>) {
  return (
    <Container onClick={onClick}>
      {icon}
      <StyledTypography>{text}</StyledTypography>
    </Container>
  );
}

export default memo(ActionPosition);
