import { useCallback, useEffect } from 'react';
import { LogInAndJoinToCourseRequest, LogInRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { clearLoginError, login, loginAndJoinToCourse, selectLoginError } from '../authSlice';
import { LoginForm } from './LoginForm';

type Props = {
  joinCode?: string;
  onEmailChange: (email: string) => void;
};

export function LoginFormContainer({ joinCode, onEmailChange }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(selectLoginError);

  const handleSubmit = useCallback(
    (values: LogInRequest) => {
      if (!joinCode) {
        dispatch(login(values));
      } else {
        dispatch(loginAndJoinToCourse({ ...values, joinCode } as LogInAndJoinToCourseRequest));
      }
    },
    [dispatch, joinCode]
  );

  useEffect(() => {
    dispatch(clearLoginError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginForm onSubmit={handleSubmit} error={loginError} onEmailChange={onEmailChange} />;
}

export default LoginFormContainer;
