import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Stack, Typography } from '@mui/material';
import { urls } from 'Router';
import { useAppSelector } from 'Store';
import { LoginState, selectLoginState } from 'Auth/authSlice';
import { Link, Logo } from 'Components';
import { StyledLogo } from 'Components/Styled';
import { LanguageBar } from 'AppLayout/LanguageBar';
import { BottomContainer } from '../LoginPage/BottomContainer';
import { LinksContainer } from '../LoginPage/LinksContainer';
import RegistrationFormContainer from './RegistrationFormContainer';

export function RegistrationPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginState = useAppSelector(selectLoginState);

  useEffect(() => {
    if (loginState === LoginState.LOGGED) {
      navigate(urls.homePage);
    }
  }, [loginState, navigate]);

  return (
    <>
      <StyledLogo>
        <Typography noWrap>{t('auth.registrationIn')}</Typography>
        <Logo variant="login" />
      </StyledLogo>

      <RegistrationFormContainer joinCode={location.state?.joinCode} />

      <BottomContainer>
        <LinksContainer>
          <Stack direction="row" gap="5px">
            <Typography noWrap>{t('auth.alreadyHaveAnAccount')}</Typography>
            <Link
              to={urls.loginPage}
              label={t('auth.login')}
              shouldOpenInNewTab={false}
              state={{ joinCode: location.state?.joinCode }}
            />
          </Stack>

          <Link to={urls.treneoSite} label={t('auth.whatIsTreneo')} />
        </LinksContainer>

        <LanguageBar />
      </BottomContainer>
    </>
  );
}

export default RegistrationPage;
