import { useTranslation } from 'react-i18next';
import { FormContainer } from 'react-hook-form-mui';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { Spacing } from 'Themes/spacing';
import { ChangePasswordRequest } from 'Api';
import { AlertError } from 'Common/AlertError';
import { AlertSuccess } from 'Common/AlertSuccess';
import { PasswordField, PasswordAndConfirmPasswordFields } from 'Common/Form';
import { ChangePasswordSchema } from 'Common/Form/ValidationSchemas';
import { useAppForm } from 'Utils/customHooks';

const formFieldNames = {
  oldPassword: 'oldPassword',
  password: 'password',
  confirmPassword: 'confirmPassword'
} as const;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.medium};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.medium};
`;

type Props = {
  onSubmit: (formData: ChangePasswordRequest) => void;
  onCancel: () => void;
  error?: string | null;
  isSubmitSuccess?: boolean;
};

function ChangePasswordForm({ onSubmit, onCancel, error, isSubmitSuccess }: Readonly<Props>) {
  const { t } = useTranslation();
  const formContext = useAppForm<ChangePasswordRequest>(ChangePasswordSchema);

  return (
    <FormContainer onSuccess={onSubmit} formContext={formContext}>
      <Container>
        {error && <AlertError text={t(error)} />}

        {isSubmitSuccess && <AlertSuccess text={t('auth.passwordHasBeenChanged')} />}

        <PasswordField name={formFieldNames.oldPassword} label={t('auth.oldPassword')} />

        <PasswordAndConfirmPasswordFields
          formContext={formContext}
          passwordLabel={t('auth.newPassword')}
        />

        <Buttons>
          <Button type="submit" disabled={!formContext.formState.isValid}>
            {t('Save')}
          </Button>

          <Button variant="outlined" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Buttons>
      </Container>
    </FormContainer>
  );
}

export default ChangePasswordForm;
