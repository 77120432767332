import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import UserAvatar from 'Common/UserAvatar';
import { getUserDisplayName } from 'Utils/user';

const Container = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  margin-left: 8px;
  white-space: nowrap;
  font-size: clamp(12px, 1vw, 14px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  name: string | null | undefined;
  avatarColor: string;
  avatar: string | null | undefined;
  customAvatar?: ReactNode;
};

const UserInfo = ({ name, avatarColor, avatar, customAvatar }: Readonly<Props>) => {
  const userName = getUserDisplayName(name);

  return (
    <Container>
      {customAvatar ? (
        customAvatar
      ) : (
        <UserAvatar displayName={userName} color={avatarColor} avatarUrl={avatar} />
      )}
      <StyledTypography>{userName}</StyledTypography>
    </Container>
  );
};

export default UserInfo;
