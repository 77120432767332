import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectToParticipantDesktopRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import { openExternalUrl } from 'Utils/redirects';
import {
  connectToParticipantDesktop,
  selectIsParticipantDesktopDeletePending,
  selectIsParticipantDesktopStartPending,
  selectIsParticipantDesktopStopPending,
  selectParticipantDesktopViewLink
} from 'Courses/coursesSlice';
import { ConnectToDesktopButton } from './ConnectToDesktopBase';

type Props = {
  courseId: string;
  participantId: string;
  disabled?: boolean;
  isHighlighted: boolean;
  isMinimized: boolean;
};

const ConnectToParticipantDesktopButton = ({
  courseId,
  participantId,
  disabled,
  isHighlighted,
  isMinimized
}: Readonly<Props>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isParticipantDesktopStartPending = useAppSelector(state =>
    selectIsParticipantDesktopStartPending(state, courseId, participantId)
  );

  const isParticipantDesktopStopPending = useAppSelector(state =>
    selectIsParticipantDesktopStopPending(state, courseId, participantId)
  );

  const isParticipantDesktopDeletePending = useAppSelector(state =>
    selectIsParticipantDesktopDeletePending(state, courseId, participantId)
  );

  const participantDesktopViewLink = useAppSelector(state =>
    selectParticipantDesktopViewLink(state, courseId, participantId)
  );

  const redirectToDesktop = (desktopLink: string) => openExternalUrl(desktopLink);

  const shouldNotRedirectToNewTab = true;

  const connectToDesktop = () =>
    dispatch(
      connectToParticipantDesktop({ courseId, participantId } as ConnectToParticipantDesktopRequest)
    ).then(() => {
      !shouldNotRedirectToNewTab &&
        participantDesktopViewLink &&
        redirectToDesktop(participantDesktopViewLink);
    });

  return (
    <ConnectToDesktopButton
      buttonText={t('Connect')}
      onConnectToDesktopClick={connectToDesktop}
      disabled={disabled}
      isDesktopStartPending={isParticipantDesktopStartPending}
      isDesktopStopPending={isParticipantDesktopStopPending}
      isDesktopDeletePending={isParticipantDesktopDeletePending}
      isHighlighted={isHighlighted}
      isMinimized={isMinimized}
    />
  );
};

export default memo(ConnectToParticipantDesktopButton);
