import React, { memo } from 'react';

import { DateInfo } from '../DateInfo';
import { TimeInfo } from '../TimeInfo';

type Props = {
  date: Date;
};

const DateTimeInfo = ({ date }: Readonly<Props>) => (
  <span>
    <DateInfo date={date} /> <TimeInfo date={date} />
  </span>
);

export default memo(DateTimeInfo);
