import { Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {
  text: string;
};

const AlertSuccess = ({ text }: Readonly<Props>) => (
  <Alert severity="success" icon={<CheckCircleIcon />}>
    {text}
  </Alert>
);

export default AlertSuccess;
