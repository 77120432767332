import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from 'react-hook-form-mui';
import { ForgotPasswordRequest } from 'Api';
import { StyledAuthFormContainer, StyledButton } from 'Components/Styled';
import { EmailField } from 'Common/Form';
import { ForgotPasswordSchema } from 'Common/Form/ValidationSchemas/ForgotPasswordSchema';
import { useAppForm } from 'Utils/customHooks';

const formFieldNames = {
  email: 'email'
} as const;

type Props = {
  email?: string;
  onSubmit: (formData: ForgotPasswordRequest) => void;
};

export function ForgotPasswordForm({ email, onSubmit }: Readonly<Props>) {
  const { t } = useTranslation();
  const formContext = useAppForm<ForgotPasswordRequest>(ForgotPasswordSchema);

  useEffect(() => {
    formContext.setValue(formFieldNames.email, email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !formContext.formState.isSubmitSuccessful ? (
    <FormContainer onSuccess={onSubmit} formContext={formContext}>
      <StyledAuthFormContainer>
        <EmailField name={formFieldNames.email} />

        <StyledButton type="submit" size="large" disabled={!formContext.formState.isValid}>
          {t('auth.resetPassword')}
        </StyledButton>
      </StyledAuthFormContainer>
    </FormContainer>
  ) : (
    <div>
      {t('auth.resetPasswordMessagePart1')} {formContext.control._formValues.email}
      {t('auth.resetPasswordMessagePart2')}
    </div>
  );
}

export default ForgotPasswordForm;
