import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Store';
import {
  FormsDefaultValuesSettings,
  FormsDefaultValuesSettingsFreshStartForm,
  SentrySettings,
  GoogleSettings,
  configApi
} from 'Api';

export interface AppConfigState {
  sentrySettings: SentrySettings;
  googleSettings: GoogleSettings;
  formsDefaultValuesSettings: FormsDefaultValuesSettings;
  isConfigLoading: boolean;
}

const initialState: AppConfigState = {
  sentrySettings: {} as SentrySettings,
  googleSettings: {} as GoogleSettings,
  formsDefaultValuesSettings: {
    freshStartForm: {} as FormsDefaultValuesSettingsFreshStartForm
  } as FormsDefaultValuesSettings,
  isConfigLoading: false
};

export const getAppConfig = createAsyncThunk('config/getAppConfig', async () => {
  const result = await configApi.configGetConfigGet();
  return result.data;
});

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAppConfig.pending, state => {
      state.isConfigLoading = true;
    });
    builder.addCase(getAppConfig.fulfilled, (state, action) => {
      state.sentrySettings = action.payload.sentrySettings!;
      state.googleSettings = action.payload.googleSettings!;
      state.formsDefaultValuesSettings = action.payload.formsDefaultValuesSettings!;
      state.isConfigLoading = false;
    });
    builder.addCase(getAppConfig.rejected, state => {
      state.isConfigLoading = false;
    });
  }
});

export default configSlice.reducer;

const selectSelf = (state: RootState) => state.config;

export const selectSentrySettings = createSelector(selectSelf, state => state.sentrySettings);

export const selectGoogleSettings = createSelector(selectSelf, state => state.googleSettings);

export const selectShouldShowExternalLoginProviders = createSelector(
  selectSelf,
  state => !!state.googleSettings?.clientId
);

export const selectFreshStartFormDefaultValues = createSelector(
  selectSelf,
  state => state.formsDefaultValuesSettings.freshStartForm || {}
);

export const selectIsConfigLoading = createSelector(selectSelf, state => state.isConfigLoading);
