import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'Components';

type Props = {
  meetingLink: string | null | undefined;
  meetingPlatform: string | null | undefined;
};

export function MeetingLink({ meetingLink, meetingPlatform }: Readonly<Props>) {
  const { t } = useTranslation();

  return meetingLink ? (
    <Link
      to={meetingLink}
      label={`${t('coursesPage.linkToOnlineMeeting')} (${meetingPlatform})`}
      leftIcon={<ContentCopyIcon />}
    />
  ) : (
    ''
  );
}

export default memo(MeetingLink);
