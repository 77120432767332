import { useCallback, useEffect } from 'react';
import { SaveCurrentUserInfoRequest } from 'Api';
import { useAppDispatch, useAppSelector } from 'Store';
import {
  clearIsSaveCurrentUserInfoSuccess,
  saveCurrentUserInfo,
  selectCurrentUserInfo,
  selectIsSaveCurrentUserInfoSuccess
} from 'User/userSlice';
import { MyAccountForm } from './MyAccountForm';

type Props = {
  onClose: () => void;
};

export const MyAccountFormContainer = ({ onClose }: Readonly<Props>) => {
  const dispatch = useAppDispatch();

  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const isSaveCurrentUserInfoSuccess = useAppSelector(selectIsSaveCurrentUserInfoSuccess);

  const handleSubmit = useCallback(
    (formData: SaveCurrentUserInfoRequest) => {
      dispatch(
        saveCurrentUserInfo({
          firstName: formData.firstName,
          lastName: formData.lastName,
          displayName: formData.displayName,
          avatarColor: formData.avatarColor
        } as SaveCurrentUserInfoRequest)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(clearIsSaveCurrentUserInfoSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyAccountForm
      onSubmit={handleSubmit}
      onCancel={onClose}
      email={currentUserInfo.email}
      firstName={currentUserInfo.firstName}
      lastName={currentUserInfo.lastName}
      displayName={currentUserInfo.displayName}
      avatarColor={currentUserInfo.avatarColor}
      isSubmitSuccess={isSaveCurrentUserInfoSuccess}
    />
  );
};

export default MyAccountFormContainer;
