import { memo } from 'react';
import './Linkify.css';

type Props = {
  children: string;
};

function Linkify({ children }: Readonly<Props>) {
  const isUrl = (word: string) => word.startsWith('http');

  const changeToLinkIfNeeded = (word: string): string =>
    isUrl(word) ? `<a class="linkify_link" target="_blank" href="${word}">${word}</a>` : word;

  const words = children.replace(/\n/g, ' ').split(' ');

  const formattedWords = words.map((word: string): string => changeToLinkIfNeeded(word));

  const html = formattedWords.join(' ');

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}

export default memo(Linkify);
