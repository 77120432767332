import { useTranslation } from 'react-i18next';
import { FormContainer } from 'react-hook-form-mui';
import { ResetPasswordRequest } from 'Api';
import { AlertError } from 'Common/AlertError';
import { StyledAuthFormContainer, StyledButton } from 'Components/Styled';
import { PasswordAndConfirmPasswordFields } from 'Common/Form';
import { ResetPasswordSchema } from 'Common/Form/ValidationSchemas';
import { useAppForm } from 'Utils/customHooks';

type Props = {
  onSubmit: (formData: ResetPasswordRequest) => void;
  error?: string | null;
};

export function ResetPasswordForm({ onSubmit, error }: Readonly<Props>) {
  const { t } = useTranslation();
  const formContext = useAppForm<ResetPasswordRequest>(ResetPasswordSchema);

  return (
    <FormContainer onSuccess={onSubmit} formContext={formContext}>
      <StyledAuthFormContainer>
        {error && <AlertError text={t(error)} />}

        <PasswordAndConfirmPasswordFields
          formContext={formContext}
          passwordLabel={t('auth.newPassword')}
        />

        <StyledButton type="submit" size="large" disabled={!formContext.formState.isValid}>
          {t('auth.approve')}
        </StyledButton>
      </StyledAuthFormContainer>
    </FormContainer>
  );
}

export default ResetPasswordForm;
